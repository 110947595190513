import React from "react";
import { useNavigate } from "react-router-dom";

const PortfolioCards = ({ title, cardImage,baseUrl,description}) => {
  const navigate = useNavigate(); 

  function handleClick() {
    navigate(`${baseUrl}`);
  }

  return (
    <>
      <div className="portfolio-card-wrapper">
        <figure
          className="figure-rollover">
          <div className="image-wrap" onClick={handleClick}>
            <div className="image-container">
              <img src={cardImage?.[0].image1} width={600} height = "400px" alt="" />
            </div>
          </div>
        </figure>
        <div className="author-container">
          <div className="author-info">
            <h2 className="card-title">{title}</h2>
            <div className="project_description">
            <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioCards;
