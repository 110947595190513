import React, { useEffect } from "react";
import ComponentsData from "../Data/Data";
import { useLocation } from "react-router-dom";
import "../CSS/innerCard.css";
import PortfolioCards from "./portfolioCards";
import ContactUs from "./contactUs";
import mouseIcon from "../Images/mouse.svg";

const InnerCardSection = () => {
  const location = useLocation();
  const path = location.pathname;
  const selectedData = ComponentsData.find((item) => item.baseUrl === path);
  const relatedProducts = ComponentsData.filter(
    (item) => item.categorie === selectedData.categorie && item.baseUrl !== path
  ).slice(0, 2);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <div className="parent-layout-container">
        <header className="card-inner-wrapper">
          <div className="main-sub-container">
            <div className="layout-conatiner">
              <div className="project-info">
                <h4>{selectedData.title}</h4>
                <div className="seperator">
                  <p>by</p>
                </div>
                <div className="author-inner-image">
                  <img src={selectedData.authors[0].authorImage} alt="" />
                </div>
                <div className="author-inner-title">
                  <h4>{selectedData.authors[0].name}</h4>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main className="layout-body">
          <div className="project-info-conatiner">
            <div className="published-container">
              <h5>{"Published On " + "- " + selectedData.publishedDate}</h5>
            </div>
            <div className="project-name">
              <h1>{selectedData.title}</h1>
            </div>
            <div className="project-author-main">
              <div className="author-wrapper-main">
                <div className="author-inner-image">
                  <img src={selectedData.authors[0].authorImage} alt="" />
                </div>
                <div className="author-inner-title">
                  <h4>{selectedData.authors[0].name}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-container">
            <div className="hero-image-container">
              <img
                src={selectedData.cardImage[0].image1}
                alt=""
                className="hero-image"
              />
            </div>
          </div>
          {selectedData.description && (
            <div className="project-description">
              <p>{selectedData.description}</p>
            </div>
          )}

          {selectedData.cardImage[0].image2 && (
            <div className="live-preview-content">
              <div className="content-wrapper">
              <h3>
                Scroll to preview the design{" "}
              </h3>
              <span>
                  <img src={mouseIcon} width={25} height={25} alt="" />
                </span>
                </div>
              <div className="secondary-image-wrapper scrollable_container">
                <img
                  src={selectedData.cardImage[0].image2}
                  alt=""
                  className="scrollable_image"
                />
              </div>
            </div>
          )}

          {relatedProducts.length > 0 && (
            <div className="related_product" id="our_work">
              <h2 className="related-contaier-heading">
                See More Related {selectedData.categorie}
              </h2>
              <div className="main_wrapper_card">
                {relatedProducts.map((data) => {
                  return (
                    <div key={data.baseUrl} className="div-card-wrapper">
                      <PortfolioCards
                        title={data.title}
                        cardImage={data.cardImage}
                        authors={data.authors}
                        baseUrl={data.baseUrl}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <section className="conatct_us_main_container" id="contact_us">
            <ContactUs />
          </section>
          <section id="contact_project_section">
            <div className="contact_project_conatiner" id="about_us">
              <h2>
                Check Out Our{" "}
                <span>
                  <a href="https://dribbble.com/Dexterwilliam">Dribble</a>
                </span>
                ,
                <span>
                  <a href="https://www.behance.net/dexterwilliam112">Behance</a>
                </span>
                ,or drop a message at{" "}
                <span>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=techtribe@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    techtribe@gmail.com
                  </a>
                </span>
              </h2>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default InnerCardSection;
