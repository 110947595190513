import React, { useEffect, useState } from "react";
import "../CSS/banner.css";
import GridItems from "./GridItems";
import Carousel from "./CarouselComponent";


export default function Banner() {
  const [layout, setLayout] = useState("desktop");

  //useEffect to handle the change in device width and then set the layout based on that
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 765) {
        setLayout("mobile");
      } else {
        setLayout("desktop");
      }
    }
    handleResize()
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //switch case to change the layout based on mobile
  const bannerLayout = () => {
    switch (layout) {
      case "desktop":

        return <GridItems/>

      case "mobile":
                return <Carousel/>

                default:
                  return <GridItems/>
    }
  };

  return (
    <>
      <div className="flex_wrapper_banner">
        <div className="main-wrapper-banner">
          <div className="main-cotainer-banner">
            <div className="main-info-banner">
              <div className="upper_banner_heading">
                <h1>Tech <span style={{color:"#f05928"}}>Tribe</span></h1>
                <div className="banner_carousel_container">
                  {bannerLayout()}
                </div>
              </div>
              <div className="company_information">
                <p>
                  Bring Brands To Life By Our Creative & Innovative Solution
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
