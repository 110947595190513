import React, { useState, useEffect } from "react";
import { Analytics } from "@vercel/analytics/react";
import { data, Route, Routes, useLocation } from "react-router-dom";
import { SpeedInsights } from "@vercel/speed-insights/react";

//Styling
import "./App.css";
import "./CSS/cardsItem.css";

//Components
import Header from "./components/Header";
import InnerCardSection from "./components/innerCardSection";
import ComponentsData from "./Data/Data";
import Banner from "./components/banner";
import ContactUs from "./components/contactUs";
import Footer from "./components/footer";
import PortfolioCards from "./components/portfolioCards";

//Skeleton Ui
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function App() {
  const [projectNumber, setProjectNumber] = useState("");
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (location.pathname == "/") {
      const projectNumber =
        document.querySelector(".main-card-wrapper")?.childNodes.length || 0;
      setProjectNumber(projectNumber);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [location.pathname]);

  return (
    <>
      <SpeedInsights />
      <Analytics />
      <Header />
      <div className="layout_wrapper_main">
        <Routes>
          <Route
            path="/"
            element={
              <div className="main-card-container main-content-holder">
                <Banner />
                <section className="flex_body_wrapper">
                  <div className="featured-project-wrapper">
                    <div className="feature-project-container">
                      <div className="project-info-main ">
                        <div className="work-wrapper">
                          <h3> . W/01 </h3>
                        </div>
                        <div className="featured-project-heading">
                          <h4 style={{ color: "rgb(240, 89, 40)" }}>
                            {" "}
                            Featured{" "}
                          </h4>
                          <h4> 01 </h4>
                        </div>
                      </div>
                      <div className="project_feture_image_container">
                        {ComponentsData.filter(
                          (data) => data.featured == true
                        ).map((data, index) => (
                          <img
                            key={index}
                            src={data.cardImage[0].image1}
                            alt=""
                            width="100%"
                            height={600}
                            style={{ objectFit: "cover" }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="project-info-main " id="our_work">
                    <div className="work-wrapper">
                      <h3> . W/02 </h3>
                    </div>
                    <div className="featured-project-heading">
                      <h4 style={{ color: "rgb(240, 89, 40)" }}> Our Work </h4>
                      <h4> {projectNumber} </h4>
                    </div>
                  </div>
                  <div className="main-card-wrapper">
                    {loading
                      ? Array(35)
                          .fill(0)
                          .map((_, index) => (
                            <div className="skeleton_wrapper" key={index}>
                              <Skeleton width={600} height={400} />
                              <Skeleton
                                width={300}
                                height={20}
                                style={{ margin: "10px 0px" }}
                              />
                              <Skeleton width={500} height={40} />
                            </div>
                          ))
                      : ComponentsData.filter(
                          (data) => data.featured != true
                        ).map((data) => (
                          <PortfolioCards
                            key={data.baseUrl}
                            title={
                              data.title || (
                                <Skeleton width={600} height={400} />
                              )
                            }
                            cardImage={data.cardImage}
                            authors={data.authors}
                            description={data.description.slice(0, 80) + " ..."}
                            baseUrl={data.baseUrl}
                          />
                        ))}
                  </div>
                </section>
                {/* Contact Us Section */}
                <section className="conatct_us_main_container" id="contact_us">
                  <ContactUs />
                </section>

                {/* About Us Section */}
                <section id="contact_project_section">
                  <div className="contact_project_conatiner" id="about_us">
                    <h2>
                      Check Out Our{" "}
                      <span>
                        <a href="https://dribbble.com/Dexterwilliam">Dribble</a>
                      </span>
                      ,
                      <span>
                        <a href="https://www.behance.net/dexterwilliam112">
                          Behance
                        </a>
                      </span>
                      ,or drop a message at{" "}
                      <span>
                        <a
                          href="https://mail.google.com/mail/?view=cm&fs=1&to=techtribe@gmail.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          techtribe@gmail.com
                        </a>
                      </span>
                    </h2>
                  </div>
                </section>
              </div>
            }
          />
          <Route path="*" element={<InnerCardSection />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

export default App;
