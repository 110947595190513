const ComponentsData = [
  //Website
  {
    title: "FrankFurt Ballet School",
    categorie:"website",
    description:"Our website embodies bold creativity and minimalist design, with clean editorial layouts that showcase our capabilities and deliver a seamless, intuitive user experience.",
    cardImage: [
      {
        image1: require("../Images/website/website-ui-1-min.webp"),
        image2: require("../Images/full-project-1-min.webp"),
      },
    ],

    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/website/beach-fox",
    publishedDate:"Feb-02-2025",
  },

  {
    title: "Dels Luxury",
    categorie:"website",
    description:"Dels Luxury offers a refined blend of sophistication and elegance, presenting high-end experiences through sleek design and seamless functionality. Our platform reflects exclusivity, style, and premium quality, ensuring a luxurious user experience at every touchpoint.",
    cardImage: [
      {
        image1: require("../Images/website/website-ui-2-min.webp"),
        image2: require("../Images/full-project-2-min.jpg"),
      },
    ],

    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/website/dels-luxury",
    publishedDate:"Feb-02-2025",
  },

  {
    title: "Boost Drinks",
    featured:true,
    categorie:"website",
    description:"Boost Drinks provides a dynamic blend of energy and refreshment, designed to keep you at your best throughout the day. With vibrant flavors and a boost of vitality, our drinks are the perfect companion for those who demand more from their energy.",
    cardImage: [
      {
        image1: require("../Images/website/website-ui-3-min.webp"),
        image2: require("../Images/full-project-3-min.webp"),
      },
    ],

    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/website/boost-drink",
    publishedDate:"Feb-02-2025",
  },
  {
    title: "Rubidex",
    categorie:"website",
    description:"Rubidex is a leading data security company dedicated to protecting your most valuable assets. With cutting-edge technology and expert solutions, we safeguard your sensitive information from threats, ensuring peace of mind and compliance in an increasingly digital world.",
    cardImage: [
      {
        image1: require("../Images/website/website-ui-4-min.webp"),
        image2: require("../Images/full-project-4-min.webp"),
      },
    ],

    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/website/rubidex",
    publishedDate:"Feb-02-2025",
  },
  {
    title: "Elite Hair Care USA",
    categorie:"website",
    description:"Elite Hair Care USA offers premium hair care solutions designed to nourish, protect, and enhance the natural beauty of your hair. With scientifically formulated products and expert guidance, we provide high-quality treatments for healthier, stronger, and more vibrant hair.",
    cardImage: [
      {
        image1: require("../Images/website/website-ui-5-min.webp"),
        image2: require("../Images/full-project-5-min.webp"),
      },
    ],

    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/website/elite-hair-care",
    publishedDate:"Feb-02-2025",
  },
  {
    title: "Nubia Glow",
    categorie:"website",
    description:"Nubia Glow brings you radiant skincare products that enhance your natural beauty with the power of nature. Infused with nourishing ingredients, our line is designed to rejuvenate, hydrate, and illuminate your skin, leaving it glowing and healthy.",
    cardImage: [
      {
        image1: require("../Images/website/website-ui-6-min.webp"),
        image2: require("../Images/full-project-6-min.webp"),
      },
    ],

    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/website/nubia-glow",
    publishedDate:"Feb-02-2025",
  },


//3D Logo Design

{
    title: "YA Glitch Logo",
    categorie:"3d-logo",
    description:`A bold and futuristic logo design featuring the letters ¥A in a digital glitch effect. The combination of the yen currency symbol and the letter "A" gives it a tech-inspired, modern, and edgy feel, perfect for brands in finance, gaming, or digital innovation. The glitch effect adds a cyberpunk touch, making it visually striking against the dark background.`,
    cardImage: [
      {
        image1: require("../Images/3d-logo/3d-logo-1.gif"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/3d-logo-design/ya",
    publishedDate:"Feb-02-2025",
  },
{
    title: "Box",
    categorie:"3d-logo",
    description:`A sleek and modern 3D logo design representing "BOX" with depth and dimension. The dynamic structure gives it a futuristic and innovative appeal, making it perfect for brands in technology, logistics, packaging, or creative industries. The bold design ensures a strong visual impact, symbolizing stability, versatility, and forward-thinking solutions.`,
    cardImage: [
      {
        image1: require("../Images/3d-logo/3d-logo_2.gif"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/3d-logo-design/box",
    publishedDate:"Feb-02-2025",
  },
{
    title: "Woao",
    categorie:"3d-logo",
    description:`Woao represents energy, innovation, and a modern aesthetic. With a sleek and engaging design, this brand identity embodies creativity and forward-thinking solutions. Whether in tech, fashion, or lifestyle, Woao stands out with its unique and impactful presence.`,
    cardImage: [
      {
        image1: require("../Images/3d-logo/3d-logo_3.gif"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/3d-logo-design/Woao",
    publishedDate:"Feb-02-2025",
  },
{
    title: "Lunari",
    categorie:"3d-logo",
    description:`Lunari is a fashion brand that embodies sophistication, modern elegance, and timeless style. Inspired by the beauty of the moon, our designs blend luxurious fabrics with effortless silhouettes, creating fashion that is both chic and celestial.`,
    cardImage: [
      {
        image1: require("../Images/3d-logo/3d-logo_4.gif"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/3d-logo-design/lunari",
    publishedDate:"Feb-02-2025",
  },
{
    title: "NEON",
    categorie:"3d-logo",
    description:`Lunari is a fashion brand that embodies sophistication, modern elegance, and timeless style. Inspired by the beauty of the moon, our designs blend luxurious fabrics with effortless silhouettes, creating fashion that is both chic and celestial.`,
    cardImage: [
      {
        image1: require("../Images/3d-logo/3d-logo_5.gif"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/3d-logo-design/neon",
    publishedDate:"Feb-02-2025",
  },
{
    title: "DE FilmFabriek",
    categorie:"3d-logo",
    description:`DE FilmFabriek is a hub of creativity and innovation, dedicated to producing high-quality films with a touch of artistic brilliance. From concept to screen, we bring stories to life with precision, passion, and cinematic expertise.`,
    cardImage: [
      {
        image1: require("../Images/3d-logo/3d-logo_6.gif"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/3d-logo-design/defilmfabriek",
    publishedDate:"Feb-02-2025",
  },

{
    title: "Reality X Dealer",
    categorie:"graphic-design",
    description:`Reality X Dealer: Crafting innovative brand identities that stand out in the digital and physical world. We specialize in creating unique, impactful visuals that align with your business values and engage your audience effectively.`,
    cardImage: [
      {
        image1: require("../Images/Brand Identity/brand_identity_1.webp"),
        image2: require("../Images/brand_identity_full_1.jpg"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/graphic-design/reality-x-dealer",
    publishedDate:"Feb-02-2025",
  },
{
    title: "Orga",
    categorie:"graphic-design",
    description:`Orga: Crafting visually compelling and sustainable package designs that captivate consumers. We blend creativity with functionality to ensure your brand stands out while delivering an unforgettable unboxing experience.`,
    cardImage: [
      {
        image1: require("../Images/Package Design/package_design_1.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/graphic-design/orga",
    publishedDate:"Feb-02-2025",
  },
{
    title: "llamify",
    categorie:"graphic-design",
    description:`Llamify is all about crafting a distinctive and memorable brand identity through creative logo design. We blend fun, professionalism, and modern aesthetics to deliver logos that truly represent your vision. Whether you're launching a new business or refreshing your look, Llamify ensures a design that stands out and resonates with your audience.`,
    cardImage: [
      {
        image1: require("../Images/Logo/logo_2.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/graphic-design/llamify",
    publishedDate:"Feb-02-2025",
  },
{
    title: "The Blue Kangaroo",
    categorie:"graphic-design",
    description:`We craft unique and impactful designs that elevate your brand. From logos to marketing materials, our work ensures your brand stands out. Explore our portfolio to see creativity in action.`,
    cardImage: [
      {
        image1: require("../Images/Logo/logo_1.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/graphic-design/the-blue-kangaroo",
    publishedDate:"Feb-02-2025",
  },
{
    title: "Cat Swimming Illustration",
    categorie:"graphic-design",
    description:`A playful illustration of a fluffy orange tabby cat swimming in a serene blue pond, surrounded by gentle ripples and water lilies. The cat paddles joyfully, its tail floating behind, creating a whimsical and charming atmosphere.`,
    cardImage: [
      {
        image1: require("../Images/Illustartion/illustration_1.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/graphic-design/cat-swimming-illustration",
    publishedDate:"Feb-02-2025",
  },
{
    title: "Extra Inning",
    categorie:"graphic-design",
    description:`The Extra Inning logo captures the excitement of the game with a sleek, modern design. Bold typography paired with a subtle nod to sports, this logo embodies energy, passion, and determination, making it the perfect representation of a brand that's always ready for the next play.`,
    cardImage: [
      {
        image1: require("../Images/Logo/logo_3.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/graphic-design/extra-inning",
    publishedDate:"Feb-02-2025",
  },
{
    title: "Bored Ape",
    categorie:"nft-artwork",
    description:`Bored Ape is a collection of unique, hand-drawn NFTs featuring playful, quirky apes with distinct traits. Each ape represents a rare digital collectible with access to exclusive virtual experiences.`,
    cardImage: [
      {
        image1: require("../Images/nft/nft_artwork_1.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/nft-artwork/bored-ape",
    publishedDate:"Feb-02-2025",
  },
{
    title: "Swaggy Racon",
    categorie:"nft-artwork",
    description:`Swaggy Racon is an exclusive NFT collection of uniquely styled raccoons, each with distinct swag and character. These rare digital collectibles offer a fun and fashionable twist to the NFT world.`,
    cardImage: [
      {
        image1: require("../Images/nft/nft_artwork_2.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/nft-artwork/swaggy-racon",
    publishedDate:"Feb-05-2025",
  },
{
    title: "Monkey Smoking",
    categorie:"nft-artwork",
    description:`Monkey Smoking is a distinctive NFT collection featuring hand-drawn monkeys with unique smoking accessories. Each NFT offers a rebellious and edgy vibe, making it a standout digital collectible in the NFT space.`,
    cardImage: [
      {
        image1: require("../Images/nft/nft_artwork_3.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/nft-artwork/monkey-smoking",
    publishedDate:"Feb-05-2025",
  },
{
    title: "Astronaut Horse",
    categorie:"nft-artwork",
    description:`Astronaut Horse is a unique NFT collection blending space exploration and equine elegance. Each digital collectible features a horse in an astronaut suit, offering a futuristic and adventurous twist on traditional art.`,
    cardImage: [
      {
        image1: require("../Images/nft/nft_artwork_4.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/nft-artwork/astronaut-horse",
    publishedDate:"Feb-05-2025",
  },
{
    title: "Monkey Boxer",
    categorie:"nft-artwork",
    description:`Monkey Boxer is a dynamic NFT collection showcasing monkeys in action-packed boxing poses. Each digital collectible captures the energy and intensity of the sport, with unique characters and bold designs.`,
    cardImage: [
      {
        image1: require("../Images/nft/nft_artwork_5.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/nft-artwork/monkey-boxer",
    publishedDate:"Feb-05-2025",
  },
{
    title: "Bored Horse",
    categorie:"nft-artwork",
    description:`Bored Horse is an NFT collection featuring horses with a quirky, laid-back vibe. Each digital collectible showcases a unique, bored-looking horse, adding humor and personality to the NFT space.`,
    cardImage: [
      {
        image1: require("../Images/nft/nft_artwork_6.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/nft-artwork/bored-horse",
    publishedDate:"Feb-05-2025",
  },
{
    title: "Hingdoong Character",
    categorie:"character-illustration",
    description:`Hingdoong Character is a playful, animated character with a mischievous smile and vibrant colors. Its quirky design and expressive features capture a fun and energetic personality, making it perfect for bringing joy and charm to any project.`,
    cardImage: [
      {
        image1: require("../Images/character/character_1.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/character/hingdoong",
    publishedDate:"Feb-06-2025",
  },
{
    title: "Hooper Tennis Character",
    categorie:"character-illustration",
    description:`Hooper Tennis Character is a female protagonist from an action-packed tennis game. She’s athletic, confident, and always ready to take on her opponents, showcasing a mix of strength and agility in her gameplay.`,
    cardImage: [
      {
        image1: require("../Images/character/character_2.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/character/hooper-tennis",
    publishedDate:"Feb-06-2025",
  },
{
    title: "Sophia Light Character",
    categorie:"character-illustration",
    description:`Sophia Light Character is a strong, determined female soldier in a warzone game. With her military gear and tactical skills, she’s ready to lead and conquer in high-stakes combat situations.`,
    cardImage: [
      {
        image1: require("../Images/character/character_3.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/character/sophia-light",
    publishedDate:"Feb-06-2025",
  },
{
    title: "Shiori Novella Character",
    categorie:"character-illustration",
    description:`Shiori Novella is a provocative anime maid character with a bold, confident personality. Her outfit and demeanor exude a playful and daring charm, standing out in the world of anime maids with a more adventurous style.`,
    cardImage: [
      {
        image1: require("../Images/character/character_4.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/character/shiori-novella",
    publishedDate:"Feb-06-2025",
  },
{
    title: "The Baddest Character",
    categorie:"character-illustration",
    description:`The Baddest Character is a fierce, cyberpunk-inspired female character. With neon tattoos, futuristic gear, and a confident, rebellious attitude, she dominates her dystopian world, exuding strength and independence in every scene.`,
    cardImage: [
      {
        image1: require("../Images/character/character_5.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/character/the-baddest",
    publishedDate:"Feb-06-2025",
  },
{
    title: "KarmaLand Character",
    categorie:"character-illustration",
    description:`KarmaLand Character is a quirky and laid-back figure, holding a shovel while casually chewing gum. With a relaxed demeanor and an easygoing attitude, this character brings a fun, playful vibe to any setting, making them stand out in their own unique way.`,
    cardImage: [
      {
        image1: require("../Images/character/character_6.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/character/karmaland",
    publishedDate:"Feb-06-2025",
  },
{
    title: "Chef Pad",
    categorie:"mobile-ui",
    description:`Chef Pad is the ultimate mobile app for culinary enthusiasts. Simplify meal planning, track ingredients, and access thousands of recipes at your fingertips. Elevate your kitchen game with Chef Pad, designed for seamless cooking experiences.`,
    cardImage: [
      {
        image1: require("../Images/Mobile UI/mobile_ui_1.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/mobile-ui/chefpad",
    publishedDate:"Feb-06-2025",
  },
{
    title: "Kitchen Control",
    categorie:"mobile-ui",
    description:`Kitchen Control puts you in command of your kitchen. Monitor appliance usage, set cooking timers, and streamline meal prep all from your mobile device. Take control of your kitchen with ease and precision.`,
    cardImage: [
      {
        image1: require("../Images/Mobile UI/mobile_ui_2.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/mobile-ui/kitchen-control",
    publishedDate:"Feb-06-2025",
  },
{
    title: "Chef Wiz",
    categorie:"mobile-ui",
    description:`Chef Wiz is your personal culinary assistant on the go. Discover new recipes, manage ingredients, and get expert tips right at your fingertips. Cooking made effortless with Chef Wiz—your secret to kitchen mastery.`,
    cardImage: [
      {
        image1: require("../Images/Mobile UI/mobile_ui_3.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/mobile-ui/chef-wiz",
    publishedDate:"Feb-06-2025",
  },
{
    title: "Grab In",
    categorie:"mobile-ui",
    description:`Grab In makes ordering food easier than ever. Browse local menus, place orders, and enjoy your favorite meals delivered to your door with just a few taps. Fast, convenient, and delicious—Grab In has you covered.`,
    cardImage: [
      {
        image1: require("../Images/Mobile UI/mobile_ui_4.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/mobile-ui/grab-in",
    publishedDate:"Feb-06-2025",
  },
{
    title: "Matcha Kitchen",
    categorie:"mobile-ui",
    description:`Matcha Kitchen brings the vibrant world of matcha to your fingertips. Discover matcha-based recipes, beverages, and wellness tips, all tailored to boost your energy and wellness. Experience the art of matcha with Matcha Kitchen.`,
    cardImage: [
      {
        image1: require("../Images/Mobile UI/mobile_ui_5.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/mobile-ui/matcha-kitchen",
    publishedDate:"Feb-06-2025",
  },
{
    title: "Story Let",
    categorie:"mobile-ui",
    description:`Story Let transforms your reading experience with captivating audiobooks at your fingertips. Immerse yourself in a world of stories, wherever you go, and listen to your favorite books on demand. Let stories come to life with Story Let.`,
    cardImage: [
      {
        image1: require("../Images/Mobile UI/mobile_ui_6.webp"),
      },
    ],
    
    authors: [
      {
        name: "Dexter William",
        authorImage: require("../Images/dexter-william.webp"),
      },
    ],
    baseUrl: "/mobile-ui/story-let",
    publishedDate:"Feb-06-2025",
  },

];

export default ComponentsData;