import React, { useState, useRef } from "react";
import "../CSS/footer.css";
import companyLogo from "../Images/TECH TRIBE.svg";
import emailjs from '@emailjs/browser';

export default function Footer() {
  const [footerEmail, setfooterEmail] = useState({
    email:'',
  });

  const handleChange = (e) => {
    setfooterEmail({ ...footerEmail, [e.target.name]: e.target.value });
  };

   const [SubmissionSuccess, setSubmissionSuccess] = useState("");
   const [submissionError, setSubmissionError] = useState("");

   //email js configuration
 
   const form = useRef();
 
   const sendEmail = (e) => {
     e.preventDefault();
 
     const serviceId ="service_ig6hicq";
     const templateId = "template_t8hi9dk"
     const publicKey = "iefV4X3SwHw7gEMVW" 
 
     emailjs
       .sendForm(serviceId, templateId, form.current, {
         publicKey: publicKey,
       })
       .then(
         () => {
          setSubmissionSuccess(
            "✅ Success!"
          );
          setTimeout(() => setSubmissionSuccess(""), 10000);
           setfooterEmail({email:''});
         },
         (error) => {
          setSubmissionError("❌ Submission failed");
          setTimeout(() => setSubmissionError(""), 10000);
         },
       );
   };

   //email js configuration end

  return (
    <>
      <div className="footer-wrapper">
        <div className="footer-container">
          <footer>
            <div className="main-wrapper-footer">
              <div className="footer_heading">
                <h2>Give it your best, or don’t do it at all.</h2>
              </div>
              <div className="form_link_main">
                <div className="footer_link_main_wrapper">
                  <div className="business_email_wrapp">
                    <div className="email_heading_footer">
                      <h3>Grow With Us:</h3>
                    </div>
                    <a href="#">techtribe@gmail.com</a>
                  </div>
                  <div className="footer_form_wrapper">
                    <form action="" id="module_id_2" ref={form}>
                      <div className="form_input_feild_wrapper">
                        <div className="label_wrapper_email">
                          <label htmlFor="Email">
                            Sign Up For Our Newsletter
                          </label>
                        </div>
                        <div className="email_input_wrapper">
                          <input
                            type="email"
                            className="footer_email"
                            placeholder="Email"
                            value={footerEmail.email}
                            name="email"
                            onChange={handleChange}
                          />
                          <button type="submit" onClick={sendEmail} className="footer_email_submit">
                            <img src={require("../Images/icon 1.png")} alt="" />
                          </button>
                        </div>
                        {SubmissionSuccess && (
                            <p className="submission_succes">
                              {SubmissionSuccess}
                            </p>
                          )}
                          {submissionError && (
                            <p className="submission_error">
                              {submissionError}
                            </p>
                          )}
                      </div>
                    </form>
                  </div>
                </div>
                <div className="footer_quick_link">
                  <h2>Website Links</h2>
                  <ul>
                    <li className="nav-child-comp">
                      {" "}
                      <a href="#our_work">Work</a>
                    </li>
                    <li className="nav-child-comp">
                      {" "}
                      <a href="#about_us"> About</a>
                    </li>
                    <li className="nav-child-comp">
                      {" "}
                      <a href="#contact_us">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer_company_name">
                <span>
                  <img src={companyLogo} alt="" width="100%" />
                </span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}
