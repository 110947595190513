import React, {useEffect} from "react";
import ComponentsData from "../Data/Data";
import "../CSS/carousel.css";

export default function GridItems() {
    // Preload images
    useEffect(() => {
      ComponentsData.forEach((item) => {
        const img = new Image();
        img.src = item.cardImage[0].image1;
      });
    }, []);
  return (
    <div>
      <div className="carusel_wrapper">
        <div className="carousel_container">
          <div className="parent_container_carousel">
            {ComponentsData.map((data, index) => (
              <div
                className="listed_carousel_children hover_container"
                key={index}
              >
               
                  <img
                    src={data.cardImage[0].image1}
                    width="100%"
                    height="300px"
                    alt=""
                    fetchPriority="high"
                  />
    
                <div className="hover_container_grid">
                <a href={data.baseUrl} className="banner_link"><button className="banner_link_button">View</button></a>
                </div>
              </div>
            )).slice(0, 6)}
            ;
          </div>
        </div>
      </div>
    </div>
  );
}
