import React, { useState, useRef } from "react";
import "../CSS/contact.css";
import emailjs from '@emailjs/browser';

export default function ContactUs() {
  const [formData, setFormData] = useState({
    company: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    aboutProject: "",
    pageLink: window.location.href,
    submitDate: new Date(),
  });

  const [SubmissionSuccess, setSubmissionSuccess] = useState("");
  const [submissionError, setSubmissionError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    const serviceId ="service_ig6hicq";
    const templateId = "template_e9gcc1s"
    const publicKey = "iefV4X3SwHw7gEMVW" 

    emailjs
      .sendForm(serviceId, templateId, form.current, {
        publicKey: publicKey,
      })
      .then(
        () => {
          setSubmissionSuccess("✅ Your project details have been submitted successfully!")
          setFormData({
            company: "",
            fullName: "",
            email: "",
            phoneNumber: "",
            aboutProject: "",
            pageLink: window.location.href,
        submitDate: new Date(),
          });
          setTimeout(()=> setSubmissionSuccess(""), 10000);
        },
        (error) => {
          setSubmissionError("❌ Submission failed. Please try again.")
          setTimeout(()=> setSubmissionError(""), 10000)
        },
      );
  };



  return (
    <>
      <div className="contact_us_info_wrapper">
        <h2 className="main_heading_contact">let’s work <span style={{color:"rgb(240, 89, 40)"}}>together </span></h2>
        <p className="conatct-us-info">
          Submit your project details below and we’llget back to you.
        </p>
      </div>
      <div className="form-container">
        <div className="main-form-wrapper">
          <div className="form">
          <form id="module_id_1" ref={form} onSubmit={sendEmail}>
              <div className="form_fields_grid">
                <div className="form_fields">
                  <input
                    name="company"
                    type="text"
                    className="company_form_input"
                    placeholder="Company Name"
                    required
                    onChange={handleChange}
                    value={formData.company}
                  />
                </div>

                <div className="form_fields_2">
                  <input
                    name="fullName"
                    type="text"
                    className="full_name_input"
                    required
                    placeholder="Full Name"
                    onChange={handleChange}
                    value={formData.fullName}
                  />
                </div>
              </div>

              <div className="form_fields_grid">
                <div className="form_fields_3">
                  <input
                    name="email"
                    type="email"
                    className="email_form_input"
                    required
                    placeholder="Email"
                    onChange={handleChange}
                    value={formData.email}
                  />
                </div>
                <div className="form_fields_4">
                  <input
                    name="phoneNumber"
                    type="tel"
                    className="phone_form_input"
                    required
                    placeholder="Phone Number"
                    onChange={handleChange}
                    value={formData.phoneNumber}
                  />
                </div>
              </div>
              <div className="textarea_form" required>
                <textarea
                  name="aboutProject"
                  placeholder="About Project"
                  onChange={handleChange}
                  value={formData.aboutProject}
                ></textarea>
              </div>
              <div className="webpage_location_link">
                <input
                  style={{ display: "none" }}
                  type="hidden"
                  name="pageLink"
                  id="website_page_link"
                  value={formData.pageLink}
                />
              </div>
              <div className="date_wrapper">
                <input
                  style={{ display: "none" }}
                  type="hidden"
                  name="submitDate"
                  id="form_submit_date"
                  value={formData.submitDate}
                />
              </div>
              {SubmissionSuccess && <p className="submission_succes">{SubmissionSuccess}</p>}
              {submissionError && <p className="submission_error">{submissionError}</p>}
              <div className="submit_button" id="form_module_1_submit">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}