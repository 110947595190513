import React, { useState, useEffect } from "react";
import ComponentsData from "../Data/Data";
import "../CSS/carouselComponent.css";
import carouselIcon from "../Images/carouselIcon.svg"

const Carousel = () => {
  const [index, setIndex] = useState(0);
  const length = 5;
  const handlePrevious = () => {
    const newIndex = index - 1;
    setIndex(newIndex < 0 ? length : newIndex);
  };
  const handleNext = () => {
    const newIndex = index + 1;
    setIndex(newIndex > length ? 0 : newIndex);
  };

      // Preload images
      useEffect(() => {
        ComponentsData.forEach((item) => {
          const img = new Image();
          img.src = item.cardImage[0].image1;
        });
      }, []);

  return (
    <>
      <div className="carousel_wrapper">
        <div
          className="carousel_container_mobile"
          style={{ transform: `translateX(-${index * 100}%)` }}
        >
          {ComponentsData.map((data, i) => (

            <div className="carousel_slide" key={data.baseUrl}>
                           <a href={data.baseUrl} className="banner_link">
              <img
                src={data.cardImage[0].image1}
                alt={data.title}
                className="carousel_image"
                fetchPriority="high"
              />
                          </a>
            </div>

          ))}
        </div>
        <div className="carousel_button_container">
        <button onClick={handlePrevious} className="carousel_button">
          <img src={carouselIcon} width={20} height={20} style={{transform:"rotate(180deg)"}} alt="" />
        </button>
        <button onClick={handleNext} className="carousel_button">
          <img src={carouselIcon} width={20} height={20} alt="" />
        </button>
        </div>
      </div>
    </>
  );
};

export default Carousel;