import React from "react";
import "../CSS/Header.css";
import mobileMenuOpen from "../Images/mobileMenuOpen.svg";
import mobileMenuClose from "../Images/mobileMenuClose.svg";
import logoSvg from '../Images/SVG/texh tribe.svg'

export default function Header() {
  function mobileNavopen() {
    let mobileOpenIcon = document.querySelector(".mobile-menu-open");
    let mobileCloseIcon = document.querySelector(".mobile-menu-close");
    let mainMobileMenu = document.querySelector(".flex-nav-wrapper");

    mobileOpenIcon.setAttribute("aria-hidden", "true");
    mobileCloseIcon.setAttribute("aria-hidden", "false");

    mainMobileMenu.style.display = "block";
    document.body.style.overflow = "hidden"
  }

  function mobileNavClose() {
    let mobileOpenIcon = document.querySelector(".mobile-menu-open");
    let mobileCloseIcon = document.querySelector(".mobile-menu-close");
    let mainMobileMenu = document.querySelector(".flex-nav-wrapper");

    mobileOpenIcon.setAttribute("aria-hidden", "false");
    mobileCloseIcon.setAttribute("aria-hidden", "true");

    mainMobileMenu.style.display = "none";
    document.body.style.overflow = "auto"
  }

  function navLinkClick(){
    let windowWidth = window.innerWidth;
if(windowWidth <= 765){
  let mobileOpenIcon = document.querySelector(".mobile-menu-open");
    let mobileCloseIcon = document.querySelector(".mobile-menu-close");
    let mainMobileMenu = document.querySelector(".flex-nav-wrapper");

    mobileOpenIcon.setAttribute("aria-hidden", "false");
    mobileCloseIcon.setAttribute("aria-hidden", "true");

    mainMobileMenu.style.display = "none";
    document.body.style.overflow = "auto"
}
  }

  return (
    <div>
      <div className="header-container">
        <div className="main-header-wrapper">
          <div className="logo-container">
            <h2>
              <a href="/"><img src={logoSvg} width={40} height={40} alt="" /></a>
            </h2>
          </div>
          <div className="main-nav-container">
            <div className="flex-nav-wrapper">
              <nav id="website-nav">
                <ul>
                  <a href="#our_work" onClick={navLinkClick}><li className="nav-child-comp">Work</li></a>
                 <a href="#about_us" onClick={navLinkClick}> <li className="nav-child-comp">About</li></a>
                  <a href="#contact_us" onClick={navLinkClick}><li className="nav-child-comp">Contact Us</li></a>
                </ul>
              </nav>
            </div>
            <div className="mobile-menu-toggle">
              <div className="mobile-menu-open" aria-hidden="false">
                <img
                  src={mobileMenuOpen}
                  alt=""
                  onClick={mobileNavopen}
                  width="20px"
                  height="20px"
                />
              </div>
              <div className="mobile-menu-close" aria-hidden="true">
                <img
                  src={mobileMenuClose}
                  alt=""
                  onClick={mobileNavClose}
                  width="20px"
                  height="20px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
